/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <header id="header" className="fixed-top">
          <div className="container d-flex">
            <div className="logo mr-auto">
              <h1 className="text-light">
                <img src="img/logo.png" style={{ width: "75px" }} alt="" />
              </h1>
            </div>

            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li className="active">
                  <a href="#hero">Home</a>
                </li>
                <li>
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#portfolio" value="">
                    Gallery
                  </a>
                </li>
                {/* <li>
                  <a href="#team">Team</a>
                </li> */}
                {/* <li className="drop-down">
                  <a href="#">Drop Down</a>
                  <ul>
                    <li>
                      <a href="#">Drop Down 1</a>
                    </li>
                    <li className="drop-down">
                      <a href="#">Drop Down 2</a>
                      <ul>
                        <li>
                          <a href="#">Deep Drop Down 1</a>
                        </li>
                        <li>
                          <a href="#">Deep Drop Down 2</a>
                        </li>
                        <li>
                          <a href="#">Deep Drop Down 3</a>
                        </li>
                        <li>
                          <a href="#">Deep Drop Down 4</a>
                        </li>
                        <li>
                          <a href="#">Deep Drop Down 5</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Drop Down 5</a>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <section
          id="hero"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div
            className="container text-center text-md-left"
            data-aos="fade-up"
          >
            <h1>Welcome to WD Carpentry</h1>
            <h2>
              Custom carpentry renovation and installation of wood stairs with
              wood and metal baluster, wood moldings, residential bar,
              bookshelves, and more...
            </h2>
            <a href="#about" className="btn-get-started scrollto">
              Get Started
            </a>
          </div>
        </section>

        <main id="main">
          <section id="about" className="about">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-7" data-aos="fade-right">
                  <img src="/img/wood_stair.jpg" className="img-fluid" alt="" />
                </div>
                <div className="col-xl-6 col-lg-5 pt-5 pt-lg-0">
                  <h3 data-aos="fade-up">Voluptatem dignissimos provident</h3>
                  <p data-aos="fade-up">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="icon-box" data-aos="fade-up">
                    <i className="bx bx-receipt"></i>
                    <h4>Corporis voluptates sit</h4>
                    <p>
                      Consequuntur sunt aut quasi enim aliquam quae harum
                      pariatur laboris nisi ut aliquip
                    </p>
                  </div>

                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-cube-alt"></i>
                    <h4>Ullamco laboris nisi</h4>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt
                    </p>
                  </div>

                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bx bx-cube-alt"></i>
                    <h4>Ullamco laboris nisi</h4>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="steps" className="steps section-bg">
            <div className="container">
              <div className="row no-gutters">
                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                >
                  <span>01</span>
                  <h4>Lorem Ipsum</h4>
                  <p>
                    Ulamco laboris nisi ut aliquip ex ea commodo consequat. Et
                    consectetur ducimus vero placeat
                  </p>
                </div>

                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                  data-aos-delay="100"
                >
                  <span>02</span>
                  <h4>Repellat Nihil</h4>
                  <p>
                    Dolorem est fugiat occaecati voluptate velit esse. Dicta
                    veritatis dolor quod et vel dire leno para dest
                  </p>
                </div>

                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  <span>03</span>
                  <h4> Ad ad velit qui</h4>
                  <p>
                    Molestiae officiis omnis illo asperiores. Aut doloribus
                    vitae sunt debitis quo vel nam quis
                  </p>
                </div>

                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                  data-aos-delay="300"
                >
                  <span>04</span>
                  <h4>Repellendus molestiae</h4>
                  <p>
                    Inventore quo sint a sint rerum. Distinctio blanditiis
                    deserunt quod soluta quod nam mider lando casa
                  </p>
                </div>

                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                  data-aos-delay="400"
                >
                  <span>05</span>
                  <h4>Sapiente Magnam</h4>
                  <p>
                    Vitae dolorem in deleniti ipsum omnis tempore voluptatem.
                    Qui possimus est repellendus est quibusdam
                  </p>
                </div>

                <div
                  className="col-lg-4 col-md-6 content-item"
                  data-aos="fade-in"
                  data-aos-delay="500"
                >
                  <span>06</span>
                  <h4>Facilis Impedit</h4>
                  <p>
                    Quis eum numquam veniam ea voluptatibus voluptas. Excepturi
                    aut nostrum repudiandae voluptatibus corporis sequi
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section id="features" className="features">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 mb-5 mb-lg-0" data-aos="fade-right">
                  <ul className="nav nav-tabs flex-column">
                    <li className="nav-item">
                      <a
                        className="nav-link active show"
                        data-toggle="tab"
                        href="#tab-1"
                      >
                        <h4>Modi sit est</h4>
                        <p>
                          Quis excepturi porro totam sint earum quo nulla
                          perspiciatis eius.
                        </p>
                      </a>
                    </li>
                    <li className="nav-item mt-2">
                      <a className="nav-link" data-toggle="tab" href="#tab-2">
                        <h4>Unde praesentium sed</h4>
                        <p>Voluptas vel esse repudiandae quo excepturi.</p>
                      </a>
                    </li>
                    <li className="nav-item mt-2">
                      <a className="nav-link" data-toggle="tab" href="#tab-3">
                        <h4>Pariatur explicabo vel</h4>
                        <p>
                          Velit veniam ipsa sit nihil blanditiis mollitia natus.
                        </p>
                      </a>
                    </li>
                    <li className="nav-item mt-2">
                      <a className="nav-link" data-toggle="tab" href="#tab-4">
                        <h4>Nostrum qui quasi</h4>
                        <p>
                          Ratione hic sapiente nostrum doloremque illum nulla
                          praesentium id
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-7 ml-auto" data-aos="fade-left">
                  <div className="tab-content">
                    <div className="tab-pane active show" id="tab-1">
                      <figure>
                        <img
                          src="/img/stair2.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                    <div className="tab-pane" id="tab-2">
                      <figure>
                        <img
                          src="/img/stair3.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                    <div className="tab-pane" id="tab-3">
                      <figure>
                        <img
                          src="/img/stair4.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                    <div className="tab-pane" id="tab-4">
                      <figure>
                        <img
                          src="/img/stair5.jpg"
                          alt=""
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="services" className="services section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Services</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas..
                </p>
              </div>

              <div className="row">
                <div
                  className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                  data-aos="fade-up"
                >
                  <div className="icon-box icon-box-pink">
                    <div className="icon">
                      <i className="bx bxl-dribbble"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Lorem Ipsum</a>
                    </h4>
                    <p className="description">
                      Voluptatum deleniti atque corrupti quos dolores et quas
                      molestias excepturi sint occaecati cupiditate
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon-box icon-box-cyan">
                    <div className="icon">
                      <i className="bx bx-file"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Sed ut perspiciatis</a>
                    </h4>
                    <p className="description">
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon-box icon-box-green">
                    <div className="icon">
                      <i className="bx bx-tachometer"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Magni Dolores</a>
                    </h4>
                    <p className="description">
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon-box icon-box-blue">
                    <div className="icon">
                      <i className="bx bx-world"></i>
                    </div>
                    <h4 className="title">
                      <a href="">Nemo Enim</a>
                    </h4>
                    <p className="description">
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section id="testimonials" className="testimonials">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Testimonials</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas.
                </p>
              </div>

              <div
                className="owl-carousel testimonials-carousel"
                data-aos="fade-up"
              >
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="/img/testimonials/testimonials-1.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>

                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse
                    labore quem cillum quid cillum eram malis quorum velit fore
                    eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="/img/testimonials/testimonials-2.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>

                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Enim nisi quem export duis labore cillum quae magna enim
                    sint quorum nulla quem veniam duis minim tempor labore quem
                    eram duis noster aute amet eram fore quis sint minim.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="/img/testimonials/testimonials-3.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                </div>

                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                    multos export minim fugiat minim velit minim dolor enim duis
                    veniam ipsum anim magna sunt elit fore quem dolore labore
                    illum veniam.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="/img/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                </div>

                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam culpa
                    fore nisi cillum quid.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img
                    src="/img/testimonials/testimonials-5.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </div>
          </section> */}

          <section id="portfolio" className="portfolio section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Gallery</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas.
                </p>
              </div>

              <div className="row" data-aos="fade-up">
                <div className="col-lg-12 d-flex justify-content-center">
                  <ul id="portfolio-flters">
                    <li data-filter="*" className="filter-active">
                      All
                    </li>
                    <li data-filter=".filter-app">Stair</li>
                    <li data-filter=".filter-card">Bookshelves</li>
                    <li data-filter=".filter-web">Wardrobe</li>
                  </ul>
                </div>
              </div>

              <div className="row portfolio-container" data-aos="fade-up">
                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>App 1</h4>
                      {/* <p>App</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port1.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="App 1"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Web 3</h4>
                      {/* <p>Web</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port2.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Web 3"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port3.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>App 2</h4>
                      {/* <p>App</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port3.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="App 2"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port4.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Card 2</h4>
                      {/* <p>Card</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port4.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Card 2"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port5.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Web 2</h4>
                      {/* <p>Web</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port5.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Web 2"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port6.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>App 3</h4>
                      {/* <p>App</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port6.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="App 3"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port7.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Card 1</h4>
                      {/* <p>Card</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port7.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Card 1"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port8.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Card 3</h4>
                      {/* <p>Card</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port8.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Card 3"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <div className="portfolio-wrap">
                    <img
                      src="/img/portfolio/port9.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="portfolio-info">
                      <h4>Web 3</h4>
                      {/* <p>Web</p> */}
                      <div className="portfolio-links">
                        <a
                          href="/img/portfolio/port9.jpg"
                          data-gall="portfolioGallery"
                          className="venobox"
                          title="Web 3"
                        >
                          <i className="bx bx-plus"></i>
                        </a>
                        {/* <a href="portfolio-details.html" title="More Details">
                          <i className="bx bx-link"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section id="team" className="team">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Team</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas.
                </p>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6" data-aos="fade-up">
                  <div className="member">
                    <img
                      src="/img/team/team-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Walter White</h4>
                        <span>Chief Executive Officer</span>
                      </div>
                      <div className="social">
                        <a href="">
                          <i className="icofont-twitter"></i>
                        </a>
                        <a href="">
                          <i className="icofont-facebook"></i>
                        </a>
                        <a href="">
                          <i className="icofont-instagram"></i>
                        </a>
                        <a href="">
                          <i className="icofont-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="member">
                    <img
                      src="/img/team/team-2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                      </div>
                      <div className="social">
                        <a href="">
                          <i className="icofont-twitter"></i>
                        </a>
                        <a href="">
                          <i className="icofont-facebook"></i>
                        </a>
                        <a href="">
                          <i className="icofont-instagram"></i>
                        </a>
                        <a href="">
                          <i className="icofont-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="member">
                    <img
                      src="/img/team/team-3.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>William Anderson</h4>
                        <span>CTO</span>
                      </div>
                      <div className="social">
                        <a href="">
                          <i className="icofont-twitter"></i>
                        </a>
                        <a href="">
                          <i className="icofont-facebook"></i>
                        </a>
                        <a href="">
                          <i className="icofont-instagram"></i>
                        </a>
                        <a href="">
                          <i className="icofont-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="member">
                    <img
                      src="/img/team/team-4.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                      </div>
                      <div className="social">
                        <a href="">
                          <i className="icofont-twitter"></i>
                        </a>
                        <a href="">
                          <i className="icofont-facebook"></i>
                        </a>
                        <a href="">
                          <i className="icofont-instagram"></i>
                        </a>
                        <a href="">
                          <i className="icofont-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section id="faq" className="faq section-bg">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>F.A.Q</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas.
                </p>
              </div>

              <div className="faq-list">
                <ul>
                  <li data-aos="fade-up">
                    <i className="bx bx-help-circle icon-help"></i>{" "}
                    <a
                      data-toggle="collapse"
                      className="collapse"
                      href="#faq-list-1"
                    >
                      Non consectetur a erat nam at lectus urna duis?{" "}
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="faq-list-1"
                      className="collapse show"
                      data-parent=".faq-list"
                    >
                      <p>
                        Feugiat pretium nibh ipsum consequat. Tempus iaculis
                        urna id volutpat lacus laoreet non curabitur gravida.
                        Venenatis lectus magna fringilla urna porttitor rhoncus
                        dolor purus non.
                      </p>
                    </div>
                  </li>

                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-help-circle icon-help"></i>{" "}
                    <a
                      data-toggle="collapse"
                      href="#faq-list-2"
                      className="collapsed"
                    >
                      Feugiat scelerisque varius morbi enim nunc?{" "}
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="faq-list-2"
                      className="collapse"
                      data-parent=".faq-list"
                    >
                      <p>
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </p>
                    </div>
                  </li>

                  <li data-aos="fade-up" data-aos-delay="200">
                    <i className="bx bx-help-circle icon-help"></i>{" "}
                    <a
                      data-toggle="collapse"
                      href="#faq-list-3"
                      className="collapsed"
                    >
                      Dolor sit amet consectetur adipiscing elit?{" "}
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="faq-list-3"
                      className="collapse"
                      data-parent=".faq-list"
                    >
                      <p>
                        Eleifend mi in nulla posuere sollicitudin aliquam
                        ultrices sagittis orci. Faucibus pulvinar elementum
                        integer enim. Sem nulla pharetra diam sit amet nisl
                        suscipit. Rutrum tellus pellentesque eu tincidunt.
                        Lectus urna duis convallis convallis tellus. Urna
                        molestie at elementum eu facilisis sed odio morbi quis
                      </p>
                    </div>
                  </li>

                  <li data-aos="fade-up" data-aos-delay="300">
                    <i className="bx bx-help-circle icon-help"></i>{" "}
                    <a
                      data-toggle="collapse"
                      href="#faq-list-4"
                      className="collapsed"
                    >
                      Tempus quam pellentesque nec nam aliquam sem et tortor
                      consequat?{" "}
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="faq-list-4"
                      className="collapse"
                      data-parent=".faq-list"
                    >
                      <p>
                        Molestie a iaculis at erat pellentesque adipiscing
                        commodo. Dignissim suspendisse in est ante in. Nunc vel
                        risus commodo viverra maecenas accumsan. Sit amet nisl
                        suscipit adipiscing bibendum est. Purus gravida quis
                        blandit turpis cursus in.
                      </p>
                    </div>
                  </li>

                  <li data-aos="fade-up" data-aos-delay="400">
                    <i className="bx bx-help-circle icon-help"></i>{" "}
                    <a
                      data-toggle="collapse"
                      href="#faq-list-5"
                      className="collapsed"
                    >
                      Tortor vitae purus faucibus ornare. Varius vel pharetra
                      vel turpis nunc eget lorem dolor?{" "}
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="faq-list-5"
                      className="collapse"
                      data-parent=".faq-list"
                    >
                      <p>
                        Laoreet sit amet cursus sit amet dictum sit amet justo.
                        Mauris vitae ultricies leo integer malesuada nunc vel.
                        Tincidunt eget nullam non nisi est sit amet. Turpis nunc
                        eget lorem dolor sed. Ut venenatis tellus in metus
                        vulputate eu scelerisque.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section id="contact" className="contact">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Contact</h2>
                <p>
                  Magnam dolores commodi suscipit. Necessitatibus eius
                  consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                  velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit
                  suscipit alias ea. Quia fugiat sit in iste officiis commodi
                  quidem hic quas.
                </p>
              </div>

              <div
                className="row no-gutters justify-content-center"
                data-aos="fade-up"
              >
                <div className="col-lg-5 d-flex align-items-stretch">
                  <div className="info">
                    {/* <div className="address">
                      <i className="icofont-google-map"></i>
                      <h4>Location:</h4>
                      <p>A108 Adam Street, New York, NY 535022</p>
                    </div> */}

                    <div className="email mt-4">
                      <i className="icofont-envelope"></i>
                      <h4>Email:</h4>
                      <p>wcosta@wdcarpentry.com</p>
                    </div>

                    <div className="phone mt-4">
                      <i className="icofont-phone"></i>
                      <h4>Call:</h4>
                      <p>+1 908 368 7117</p>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-5 d-flex align-items-stretch">
                  <iframe
                    style={{ border: "0", width: "100%", height: "270px" }}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div> */}
              </div>

              <div
                className="row mt-5 justify-content-center"
                data-aos="fade-up"
              >
                <div className="col-lg-10">
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    className="php-email-form"
                  >
                    <div className="form-row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          data-rule="minlen:4"
                          data-msg="Please enter at least 4 chars"
                        />
                        <div className="validate"></div>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          data-rule="email"
                          data-msg="Please enter a valid email"
                        />
                        <div className="validate"></div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 8 chars of subject"
                      />
                      <div className="validate"></div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="5"
                        data-rule="required"
                        data-msg="Please write something for us"
                        placeholder="Message"
                      ></textarea>
                      <div className="validate"></div>
                    </div>
                    <div className="mb-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>

        <footer id="footer">
          {/* <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="footer-info">
                    <h3>Maxim</h3>
                    <p>
                      A108 Adam Street <br />
                      NY 535022, USA
                      <br />
                      <br />
                      <strong>Phone:</strong> +1 5589 55488 55
                      <br />
                      <strong>Email:</strong> info@example.com
                      <br />
                    </p>
                    <div className="social-links mt-3">
                      <a href="#" className="twitter">
                        <i className="bx bxl-twitter"></i>
                      </a>
                      <a href="#" className="facebook">
                        <i className="bx bxl-facebook"></i>
                      </a>
                      <a href="#" className="instagram">
                        <i className="bx bxl-instagram"></i>
                      </a>
                      <a href="#" className="google-plus">
                        <i className="bx bxl-skype"></i>
                      </a>
                      <a href="#" className="linkedin">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div> */}

          {/* <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">About us</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Terms of service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div> */}

          {/* <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Web Design</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Web Development</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Product Management</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Marketing</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Graphic Design</a>
                    </li>
                  </ul>
                </div> */}

          {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>
                    Tamen quem nulla quae legam multos aute sint culpa legam
                    noster magna
                  </p>
                  <form action="" method="post">
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div> */}
          {/* </div>
            </div>
          </div> */}

          <div className="container">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>WD Carpentry 2020</span>
              </strong>
              . All Rights Reserved
            </div>
            {/* <div className="credits">
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div> */}
          </div>
        </footer>

        <a href="#" className="back-to-top">
          <i className="icofont-simple-up"></i>
        </a>
      </div>
    );
  }
}

export default App;
